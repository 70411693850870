export default function apiFetch(path: string, body?: Object) {
    return fetch(
        `${process.env.INTERNAL_URL ?? ''}${path.startsWith('/') ? path : `/${path}`}`, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': '*',
                'Content-Type': 'application/json'
            },
            // credentials: "include",
            body: JSON.stringify(body)
        });
}

class RouteService {
    getAsPath(asPath: string) {
        if (!asPath.includes('?')) return asPath;
        const s = asPath.split('?');
        if (!s || !Array.isArray(s)) return asPath;
        return s[0];
    }
}

const routeService = new RouteService();
export default routeService;
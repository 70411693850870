import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import messageService from "../../services/message/messageService";
import React from "react";
import {Alert, Snackbar} from "@mui/material";

export default function MessageBar() {
    const dispatch = useDispatch();
    const show = useSelector((state: State) => state.messageStore.show);
    const success = useSelector((state: State) => state.messageStore.success);
    const error = useSelector((state: State) => state.messageStore.error);
    const warning = useSelector((state: State) => state.messageStore.warning);
    const text = useSelector((state: State) => state.messageStore.text);

    function getType() {
        switch (true) {
            case error:
                return 'error';

            case warning:
                return 'warning';

            default:
                return 'success';
        }
    }

    function getBorder() {
        switch (true) {
            case error:
                return '#b30000';

            case warning:
                return '#b94800';

            default:
                return '#227158';
        }
    }

    if (!show) return <></>

    return (
        <Snackbar
            open={show}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            sx={{top: "10%!important"}}
            onClose={() => messageService.close(dispatch)}>
            <Alert onClose={() => messageService.close(dispatch)} severity={getType()} sx={{width: '100%', border: `1px solid ${getBorder()}`}}
                   className={"fw-bold pt-2 pb-2 no-hover"}>
                {Array.isArray(text) ? (
                    text.map((t: any, i: number) => (
                        <div key={i}>{t}</div>
                    ))
                ) : (
                    <span>{text}</span>
                )}
            </Alert>
        </Snackbar>
    )
}
import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearSearch, setLoading, setOffCanvas, setQuery, setResult} from "../../stores/search/searchSlice";
import {State} from "../../stores/store";
import {useRouter} from "next/router";
import i18next from "i18next";
import RoutePrefix from "../../services/route/prefix";


type Props = { focused?: boolean } & Pick<React.ComponentPropsWithoutRef<"input">, "onFocus" | "onBlur">

export interface Ref {
    focus(): void;
    blur(): void;
}

const SearchInput = forwardRef<Ref, Props>(function SearchInput({onFocus, onBlur, focused = false}, ref) {
    const query: string = useSelector((state: State) => state.searchState.query);
    const loading: boolean = useSelector((state: State) => state.searchState.loading);
    const dispatch = useDispatch();
    const router = useRouter();
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
        focus() {
            inputRef.current?.focus();
        },
        blur() {
            inputRef.current?.blur();
        }
    }));

    useEffect(() => {
        if (focused) {
            inputRef.current?.focus();
        }
    }, [focused]);


    function clear() {
        inputRef.current?.blur();
        dispatch(clearSearch());
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        dispatch(setOffCanvas(false));
        dispatch(setLoading(true));
        await router.push(`/${i18next.language}/${RoutePrefix.search}/${encodeURIComponent(query)}`);
        dispatch(setLoading(false));
        dispatch(setResult(null));
        inputRef.current?.blur();
    }

    return (
        <>
            <div className="d-flex flex-row input-text-icon pb-1 pb-md-3">
                <form className="d-contents" onSubmit={onSubmit}>
                    <input type="text"
                           className="form-control w-100 search-input"
                           ref={inputRef}
                           placeholder={i18next.t('general.search')} value={query}
                           onChange={(e) => dispatch(setQuery(e.target.value))}
                           onFocus={onFocus}
                           onBlur={onBlur}
                    />
                </form>

                <div>
                    <button type="button" className="btn btn-transparent h-100" aria-label={i18next.t('general.search')}
                            name={"search-button"}>
                        {(loading) ? (
                            <i className="fas fa-spinner fa-spin primary-color fs-4 search-spinner mt-0"/>
                        ) : (
                            (query.length) ? (
                                <i className="fa-solid fa-circle-xmark black search-clear mt-0"
                                   onClick={() => clear()}/>
                            ) : (
                                <i className="fa-solid fa-magnifying-glass primary-color search-magnifying mt-0"/>
                            )
                        )}
                    </button>
                </div>
            </div>
        </>
    )
});

export default SearchInput;

import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import wishlistService from "../../services/wishlistService";
import {closeListOverlay, setLists} from "../../stores/wishlist/wishlistSlice";
import WishlistAddForm from "./AddForm";
import i18next from "i18next";
import messageService from "../../services/message/messageService";

export default function WishlistOverlay() {
    const isOpen = useSelector((state: State) => state.wishlistState.isListOpen);
    const lists = useSelector((state: State) => state.wishlistState.lists);
    const orderNumber = useSelector((state: State) => state.wishlistState.orderNumber);
    const dispatch = useDispatch();

    function close() {
        dispatch(closeListOverlay())
    }

    async function addItem(id: number) {
        await wishlistService.addItem(id, orderNumber);
        dispatch(closeListOverlay());
        return messageService.success(i18next.t('wishlist.added').toString(), dispatch);
    }

    if (!isOpen) return <></>

    return (
        <>
            <div className="modal-backdrop fade show" onClick={() => close()}></div>
            <div className="modal-container position-fixed top-50 start-50 translate-middle p-4 rounded-4">
                <div className="modal-header">
                    <h5 className="modal-title">{i18next.t('list.select').toString()}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => close()}/>
                </div>
                <div className="d-flex flex-row flex-wrap pt-4 pb-4">
                    {(lists.map((list: any) => (
                        <div className="rounded-pill border-light me-4 ps-3 pe-3 pt-2 pb-2 mb-4 "
                             key={list.id}
                             role="button"
                             onClick={() => addItem(list.id)}>
                            {list.name}
                        </div>
                    )))}
                </div>
                <div className="mb-5">
                    <WishlistAddForm/>
                </div>
            </div>
        </>
    )
}
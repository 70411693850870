import i18next from "i18next";
import slugify from "../slugify";
import RoutePrefix from "../route/prefix";

class CategoryLinkService {
  blog: any = {
    de: 'magazin',
    fr: 'magazine',
    en: 'magazine',
  }

  getLink(category: any, routePrefix: string = RoutePrefix.listing, locale: string = i18next.language) {
    if (category === '/bl/blog') return `/${locale}/${this.blog[locale]}`;
    if (category.blog) return `/${locale}/${slugify(category.name.toLowerCase())}`;
    if (category.external && category.external.length) return this.parseLink(category.external, locale);
    if (!category.link) return `/${locale}/${routePrefix}/${slugify(category.name.toLowerCase())}-${category.id}`;
    return this.parseLink(category.link, locale);
  }

  parseLink(link: string, locale: string) {
    if (this.isFullUrl(link)) {
      return link;
    }
    const s = link.startsWith('/') ? link : `/${link}`;
    return (s.startsWith('/de/') || s.startsWith('/fr/') || s.startsWith('/en/')) ? s : `/${locale}${s}`
  }

  private isFullUrl(link: string) {
    try {
      new URL(link);
      return true;
    } catch {
      return false;
    }
  }
}

const categoryLinkService = new CategoryLinkService();
export default categoryLinkService;

import React from "react";
import FooterLink from "./Link";
import i18next from "i18next";
import Image from "next/image";
import Link from "next/link";
import FooterOpening from "./Opening";
import FooterPaymentMethods from "./PaymentMethods";
import FooterAboutUs from "./AboutUs";
import FooterShare from "./Share";
import FooterLinks from "./Links";
import FooterAccordionOpening from "./Accordion/Opening";
import FooterAccordionPaymentMethods from "./Accordion/PaymentMethods";
import FooterAccordionAboutUs from "./Accordion/AboutUs";
import FooterAccordionShare from "./Accordion/Share";
import FooterAccordionSecureShopping from "./Accordion/SecureShopping";

export default class Footer extends React.PureComponent<any, any> {
    render() {
        return (
            <footer className="footer pt-5 pb-5 bg-black text-white">
                <div className={"container"}>
                    <div className={"d-lg-none"}>
                        <FooterAccordionOpening/>
                        <FooterAccordionPaymentMethods/>
                        <FooterAccordionAboutUs topLinks={this.props.topLinks}/>
                        <FooterAccordionSecureShopping/>
                        <FooterAccordionShare/>
                    </div>

                    <div className="row g-5 mb-4 d-none d-lg-flex mt-3">
                        <div className="col-md-6 col-lg-3 mt-md-4 mt-lg-0 mb-4 mb-md-0">
                            <div className="col-title fs-5 fw-bold">
                                <p>{i18next.t('footer.adviceService').toString()}</p>
                            </div>
                            <div className="col-body">
                                <FooterOpening/>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-3 mt-md-4 mt-lg-0 mb-4 mb-md-0">
                            <div className="col-title fs-5 fw-bold">
                                <p>{i18next.t('footer.paymentMethods').toString()}</p>
                            </div>
                            <div className="col-body">
                                <FooterPaymentMethods/>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-2 mt-md-4 mt-lg-0 mb-4 mb-md-0">
                            <div className="fs-5 fw-bold">
                                <p>{i18next.t('general.aboutUs').toString()}</p>
                            </div>
                            <FooterAboutUs topLinks={this.props.topLinks}/>
                        </div>
                        <div className="col-md-6 col-lg-2 mt-4 mt-md-0">
                            <div className="col-title fs-5 fw-bold">
                                <p>{i18next.t('footer.secureShopping').toString()}</p>
                            </div>
                            <div className="col-body">
                                <div className="row g-0">
                                    <div className="col-12">
                                        <div className="row g-0">
                                            <div className="col-6 col-lg-12">
                                                <Link href={"https://handelsverband.swiss/members/detail/perfecthair-ag/"}
                                                      target={"_blank"}>
                                                    <Image src="/images/swiss-online-guarantee.png"
                                                           height={70}
                                                           width={70}
                                                           alt="Swiss Online Garantie"
                                                           className="d-block mb-2"/>
                                                </Link>
                                            </div>
                                            <div className="col-6 col-lg-12">
                                                <Link href={"https://www.trustedshops.ch/bewertung/info_XDE0696BEF034B75F0C5DDB6B84F78687.html"}
                                                      target={"_blank"}>
                                                    <Image src="/images/trusted-shop.png"
                                                           alt="Trusted Shop"
                                                           className="d-block"
                                                           height={70}
                                                           width={70}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-2 mt-md-4 mt-lg-0 mb-4 mb-md-0">
                            <div className="fs-5 fw-bold">
                                <p className="">{i18next.t('footer.share').toString()}</p>
                                <FooterShare/>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className={"mt-5"}/>
                <div className={"container pt-3 pb-2"}>
                    <FooterLinks links={this.props.links}/>
                </div>
                <hr/>

                <div className={"container mt-3 pt-3"}>
                    <div className={"d-flex flex-row flex-wrap justify-content-center"}>
                        {this.props.sideLinks.map((link: any) => (
                            <FooterLink key={link.id} link={link}/>
                        ))}
                    </div>

                    <div className="sup mt-1 mt-md-0 text-center">
                        <p className="fw-bold">Copyright &copy; PerfectHair AG - {i18next.t('general.copyright').toString()}</p>
                    </div>
                </div>
            </footer>
        )
    }
}
import React from "react";
import messageService from "../../services/message/messageService";
import i18next from "i18next";
import {useDispatch} from "react-redux";

type Props = React.PropsWithChildren<{ code: string; }>

export default function VoucherCodeCopy({code, children}: Props) {
    const dispatch = useDispatch();

    async function copy() {
        try {
            await navigator.clipboard.writeText(code);
            messageService.success(i18next.t('copy.codeSuccess'), dispatch);
        } catch {
            messageService.error(i18next.t('copy.codeError', {code}), dispatch);
        }
    }

    return <div role="button" className="d-inline-block" onClick={copy}>
        {children}
    </div>;
}

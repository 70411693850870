import React, {useEffect, useState} from "react";
import i18next from "i18next";
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, SwipeableDrawer} from "@mui/material";
import apiInternal from "../../../utils/apiInternal";
import {getCookie} from "cookies-next";
import {locale} from "../../../utils/locale";
import cookieService from "../../../services/cookie/cookieService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import imageService from "../../../services/product/imageService";
import Image from "next/image";
import {roundPrice} from "../../../utils/roundPrice";
import Link from "../../link/Link";
import productTranslationService from "../../../services/product/productTranslationService";
import productLinkService from "../../../services/product/productLinkService";

export default function ProductCompareOffCanvas() {
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList]: any = useState({});

    useEffect(() => {
        if (!open || !load) return;

        const fn = async () => {
            const result: any = await apiInternal().post('/api/v2/product/compare/list', {
                sessionId: getSessionCookie(),
                languageId: locale(i18next.language),
                customerGroup: cookieService.getCustomerGroup()
            })
            if (!result.data) return;
            setList(result.data);
            setLoad(false);
        }
        void fn();
    }, [open, load]);

    function getSessionCookie() {
        try {
            return getCookie('compareId')
        } catch (e) {
            return '';
        }
    }

    async function deleteItem(id: number) {
        await apiInternal().post('/api/v2/product/compare/item/delete', {sessionId: getSessionCookie(), id});
        setLoad(true);
    }

    async function deleteList(id: number) {
        await apiInternal().post('/api/v2/product/compare/delete', {sessionId: getSessionCookie(), id});
        setLoad(true);
    }

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setOpen(!open);
            setLoad(true);
        };

    return (
        <>
            <div className={"col-3"}>
                <span className="d-block text-center" aria-label={"Product compare"} role="button"
                      onClick={toggleDrawer()}>
                    <i className="fa-solid fa-code-compare"></i>
                  <p className="sup-2 d-none d-md-block">{i18next.t('productCompare.shortTitle').toString()}</p>
                </span>
            </div>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer()}
                onOpen={toggleDrawer()}>
                <div className="position-relative mui-offcanvas">
                    <div className="ps-3 pe-3 pt-4 pb-3 bg-dark text-white" onClick={() => setOpen(false)}
                         role="button">
                        <i className="fa-solid fa-circle-xmark"></i>
                        <span className="ms-2 fw-bold">{i18next.t('general.close').toString()}</span>
                    </div>

                    <div className={"p-4"}>
                        {Object.keys(list).length ? (
                            <div>
                                {Object.keys(list).map((key: any) => {
                                    return (
                                        <div key={`product-compare-group-${key}`} className={"mb-4"}>
                                            <Accordion
                                                defaultExpanded
                                                sx={{
                                                    mb: "10px",
                                                    border: "1px solid rgba(0,0,0,0.2)",
                                                    borderRadius: "10px!important",
                                                    boxShadow: "none",
                                                    "&:before": {height: 0}
                                                }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel3-content"
                                                    id="panel3-header">
                                                    <b>{list[key][0].category}</b>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <hr className={"mt-0 mb-4"}/>
                                                    {list[key].map((item: any) => (
                                                        <div key={`product-compare-item-${item.id}`}
                                                             className={"row mb-3"}>
                                                            <Link href={productLinkService.getLink(item)}
                                                                  className={"col-2"}>
                                                                <Image
                                                                    src={imageService.getImage(item.imgMainUrls, item.orderNumber)}
                                                                    height={50} width={50} alt={""}/>
                                                            </Link>
                                                            <Link href={productLinkService.getLink(item)}
                                                                  className={"col-9"}>
                                                                <div
                                                                    className={"mb-1 fw-bold"}>{productTranslationService.getShortName(item)}</div>
                                                                <p>{roundPrice(item.price)}</p>
                                                            </Link>
                                                            <div className={"col-1"}>
                                                                <i className="fa-solid fa-trash" role={"button"}
                                                                   onClick={() => deleteItem(item.compareId)}/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </AccordionDetails>
                                                <AccordionActions>
                                                    <button type="button"
                                                            aria-label={"Menu"}
                                                            onClick={() => deleteList(key)}
                                                            className="btn btn-alt ps-3 pe-3 pt-2 pb-2">
                                                        <i className="fa-solid fa-trash me-2"></i>
                                                        <span>{i18next.t('general.delete').toString()}</span>
                                                    </button>
                                                    <Link href={`/${i18next.language}/compare/${key}`}
                                                          className="btn btn-dark ps-3 pe-3 pt-2 pb-2 ms-2">
                                                        <i className="fa-solid fa-code-compare me-2 text-white"></i>
                                                        <span className={"text-white"}>
                                                            {i18next.t('general.compare').toString()}
                                                        </span>
                                                    </Link>
                                                </AccordionActions>
                                            </Accordion>

                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <p className={"mt-4"}>{i18next.t('productCompare.emptyList').toString()}</p>
                        )}
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    )
}
import FooterLink from "./Link";
import React from "react";

export default function FooterAboutUs({topLinks}: any) {
    return (
        <div className="mt-3 mt-xl-0">
            {topLinks.map((link: any) => (
                <FooterLink key={link.id} link={link} marginEnd={true}/>
            ))}
        </div>
    )
}
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React, {Dispatch, useEffect, useState} from "react";
import apiInternal from "../../utils/apiInternal";
import i18next from "i18next";
import {locale} from "../../utils/locale";
import Image from "next/image";
import Link from "next/link";
import {getCookie, setCookie} from "cookies-next";
import cookieOptions from "../../utils/cookieOptions";
import {NextRouter, useRouter} from "next/router";

const style = {
    '@media(min-width: 1200px)': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "auto",
        bgcolor: 'transparent',
        borderRadius: '10px',
        outline: 0,
        p: 4,
        overflow: "auto",
    }
};

export default function PromotionPopUp() {
    const [open, setOpen]: [boolean, Dispatch<boolean>] = useState(true);
    const [popUp, setPopUp]: [any, Dispatch<any>] = useState(null);
    const cookieName: string = "promotion-pop-up";
    const cookie = getCookie(cookieName);
    const router: NextRouter = useRouter();
    const popUpId: any = router.query.popUpId

    useEffect(() => {
        if ((router.query.sPartner || router.query.utm_source) && !popUpId) setPopUpCookie();

        const fn = async () => {
            const res = await apiInternal().post(
                '/api/v2/promotion/popup',
                {
                    languageId: locale(i18next.language),
                    id: popUpId
                }
            );
            if (!res.data) return;
            setPopUp(res.data);
        };

        void fn();
    }, [])

    function setPopUpCookie() {
        let expireDate = new Date();
        expireDate.setFullYear(expireDate.getFullYear() + 1);
        let maxAge: number = 60 * 60;
        setCookie(cookieName, true, cookieOptions(expireDate, maxAge));
    }

    const closeHandler = () => {
        setOpen(false);
        if (!popUpId) setPopUpCookie();
    }

    if (!popUp || cookie || router.query.sPartner) return <></>

    return (
        <Modal
            open={open}
            onClose={() => closeHandler()}
            aria-labelledby="Promotion"
            sx={{
                '& .MuiModal-backdrop': {
                    backgroundColor: `rgba(${popUp.backgroundColor})`,
                }
            }}
            aria-describedby="Promotion">
            <Box sx={style}>
                <i className={"fa-regular fa-xmark-large fs-1 position-absolute top-0 end-0 text-white mt-4 me-5 mt-xl-5 me-xl-0"}
                   style={{zIndex: 1000}}
                   onClick={() => closeHandler()}
                   role={"button"}/>
                <div className={"position-relative h-100 w-100 mt-0 mt-xl-5 p-5"}>
                    <div className={"row gx-xl-5 mt-2"}>
                        <div className={"col-12 col-md-6"}>
                            <Image src={popUp.image}
                                   width={1120}
                                   height={1000}
                                   alt={""}
                                   style={{maxWidth: "400px"}}
                                   className={"w-100 h-auto"}/>
                        </div>
                        <div className={"col-12 col-md-6 text-center"}>
                            <div className={"fw-bold fs-4 mt-3 mt-md-0"} style={{color: popUp.fontColor}}>
                                {popUp.title}
                            </div>
                            <div className={"fs-5 mt-3"} style={{color: popUp.fontColor}}>
                                {popUp.text}
                            </div>

                            {popUp.link && (
                                <Link href={popUp.link}
                                      onClick={() => closeHandler()}
                                      className={"ps-5 pe-5 pt-2 pb-2 mt-3 d-inline-block fw-bold"}
                                      style={
                                          {
                                              backgroundColor: "#FFFFFF",
                                              borderRadius: "5px",
                                          }
                                      }>
                                    {popUp.cta}
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
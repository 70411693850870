import {SwipeableDrawer} from "@mui/material";
import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../stores/store";
import SearchInput, {Ref} from "../Input";
import {closeSearch, setOffCanvas} from "../../../stores/search/searchSlice";
import i18next from "i18next";
import BxAutocompleteBlocks from "../../boxalino/autocomplete/Blocks";

export default function SearchResultOffCanvas() {
    const offCanvas = useSelector((state: State) => state.searchState.offCanvas);
    const result = useSelector((state: State) => state.searchState.result);
    const dispatch = useDispatch();
    const inputRef = useRef<Ref>(null);

    function clear() {
        dispatch(closeSearch());
    }

    if (result == null) return <></>

    return (
        <SwipeableDrawer
            anchor={'top'}
            open={offCanvas}
            onClose={clear}
            onOpen={() => dispatch(setOffCanvas(true))}
            onScrollCapture={() => inputRef.current?.blur()}>
            <div className={"text-center mt-3 d-md-none"} role={"button"} onClick={clear}>
                <i className="fa-solid fa-angle-up me-2"></i>
                <span>{i18next.t('general.close').toString()}</span>
            </div>

            <div className={"container ps-2 pe-2 mt-3"}>
                <SearchInput focused={true} ref={inputRef} />
            </div>

            <div className="position-relative container ms-auto me-auto mt-4 mb-2 row">
                <BxAutocompleteBlocks narrative={result} blocks={result.blocks} />
            </div>

            <hr className={"mt-4"}/>
            <div className={"text-center mb-4"} role={"button"} onClick={clear}>
                <i className="fa-solid fa-angle-up me-2"></i>
                <span>{i18next.t('general.close').toString()}</span>
            </div>
        </SwipeableDrawer>
    )
}

import {BlockProps} from "./BlockProps";
import {single} from "../../../utils/queryParams";
import {convertBxAttributes} from "../../../utils/bxAttributes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

export default function AutocompleteProductList({block, children}: BlockProps) {
    if (!block['bx-hits'].totalHitCount) {
        return <div className="row mt-4 mb-3">
            <div className="col-2 text-center">
                <FontAwesomeIcon icon={faCircleExclamation}/>
            </div>
            <div className="col-10 fs-8 fw-bold">
                {i18next.t('search.emptyResult').toString()}
            </div>
        </div>;
    }

    return <>
        <div className="fw-bold">{single(block.title)}</div>
        <hr className="mt-0 mb-3 primary-color"/>

        <ul {...convertBxAttributes(block, 'list-group list-unstyled mb-2')}>
            {children}
        </ul>
    </>;
}

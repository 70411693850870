import CartItemLink from "./ItemLink";
import Image from "next/image"
import imageService from "../../services/product/imageService";
import {imageSizes} from "../../utils/imageSizes";

export default function CartItemImage(props: any) {
    const lineItem = props.lineItem

    function render() {
        if (lineItem.mode === 42 || lineItem.mode === 2) {
            return <i className="fa-solid fa-percent fs-big"></i>
        } else if (lineItem.orderNumber === 'sw-payment') {
            return <i className="fa-sharp fa-light fa-money-bill fs-big"></i>
        } else {
            return (
                <Image src={imageService.getImage(lineItem.imgUrls, lineItem.orderNumber)}
                       height={110}
                       width={110}
                       sizes={imageSizes({
                           xs: '100px'
                       })}
                       priority={true}
                       style={{maxWidth: "120px", maxHeight: '100px'}}
                       alt={lineItem.name} className="mb-3 w-100 h-auto"/>
            )
        }
    }

    return (
        <CartItemLink lineItem={lineItem}>
            {render()}
        </CartItemLink>
    )
}
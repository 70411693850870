class SqlInjectionService {
    parse(value: any) {
        if (!value) return value;
        if (typeof value !== 'string') return value;
        if (value && !value.includes("'")) return value;
        console.warn('bx sql injection detected', value);
        try {
            return value.split("'")[0];
        }
        catch(e) {
            return '';
        }
    }
}

const sqlInjectionService = new SqlInjectionService();
export default sqlInjectionService;
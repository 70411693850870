import i18next from "i18next";
import React from "react";
import Head from "next/head";

export default function MetaTags(props: any) {
    const m = props.metaTags;
    const metaTags: any = m ? Object.fromEntries(Object.entries(m).filter(([k]: any) => k !== 'alternate')) : null;
    const alternates: any = m ? m.alternate : [];
    const index = props.noIndex ? 'noindex' : 'index';
    const follow = props.noFollow ? 'nofollow' : 'follow';

    return (
        <Head>
            <meta charSet="utf-8"/>
            <meta name="author" content="PerfectHair AG"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0"/>
            <meta name="locale" content={i18next.language}/>
            <meta name="og:site_name" content="PerfectHair.ch"/>
            <meta name="revisit-after" content="15 days"/>
            <meta name="robots" content={`${index},${follow},max-image-preview:large`}/>

            {(metaTags) ? (
                <>
                    {Object.keys(metaTags).map((key: any) => {
                        const value: any = metaTags[key];
                        if (!value) return;

                        switch (key) {
                            case key.includes('og:') || key.includes('product:'):
                                return <meta key={key} property={key} content={value}/>;

                            case 'canonical':
                                return <link key={key} rel="canonical" href={value}/>

                            default:
                                return <meta key={key} name={key} content={value}/>
                        }
                    })}
                </>
            ) : <></>}

            {alternates && alternates.length ? (
                <>
                    {alternates.map((alternate: any) => (
                        <link key={`alternate-${alternate.language}`} rel={"alternate"} hrefLang={alternate.language}
                              href={alternate.href}/>
                    ))}
                </>
            ) : <></>}

            <meta name="twitter:site" content="PerfectHair.ch"/>
            <meta itemProp="copyrightHolder" content="PerfectHair.ch"/>
            <meta itemProp="copyrightYear" content={new Date().getFullYear().toString()}/>
            <meta itemProp="isFamilyFriendly" content="True"/>
            <meta name="mobile-web-app-capable" content="yes"/>
            <meta name="apple-mobile-web-app-title" content="PerfectHair.ch"/>
            <meta name="apple-mobile-web-app-capable" content="yes"/>
            <meta name="apple-mobile-web-app-status-bar-style" content="default"/>
            <meta name="msapplication-navbutton-color" content="#D9400B"/>
            <meta name="application-name" content="PerfectHair.ch"/>
            <meta name="msapplication-starturl" content="https://www.perfecthair.ch/de/"/>
            <meta name="msapplication-window" content="width=1024;height=768"/>
            <meta name="msapplication-TileImage"
                  content="https://cdn.perfecthair.ch/media/image/93/6c/74/favicon-96x96.png"/>
            <meta name="msapplication-TileColor" content="#D9400B"/>
            <meta name="theme-color" content="#ffffff"/>
            <link rel="apple-touch-icon-precomposed"
                  href="https://cdn.perfecthair.ch/media/image/93/6c/74/favicon-96x96.png"/>
            <link rel="shortcut icon"
                  href="https://cdn.perfecthair.ch/media/unknown/ee/0d/77/favicon0PFcRtbfcUukA.ico"/>
        </Head>
    )
}

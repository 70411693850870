import React, {useState} from "react";
import apiClient from "../../../utils/apiClient";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CartOffCanvasGiftItem from "./Gift/Item";
import i18next from "i18next";
import {locale} from "../../../utils/locale";

interface Props {
    lineItem: any,
    cart?: any,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    minHeight: '200px',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    border: '1px solid transparent',
    borderRadius: '10px',
    boxShadow: 0,
    p: 4,
    overflow: "auto",
    outline: 0,
    '@media (min-width: 768px)': {
        maxHeight: '600px',
        width: '500px'
    },
};

export default function CartOffCanvasGift({lineItem, cart}: Props) {
    const [list, setList]: any = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    if (!lineItem.storedGiftId || lineItem.giftId) return <></>

    const onClick = async () => {
        const result: any = await apiClient.post('/api/gift/list', {
            id: lineItem.detailId,
            progressBar: true,
            languageId: locale(i18next.language)
        });
        setList(result.data);
        handleOpen();
    }

    const infoText = () => {
        if (lineItem.promoShippingFree) {
            if (lineItem.promoQuantity && lineItem.promoQuantity === 1) return i18next.t('cartOffCanvas.shippingFreeInfo');
            if (lineItem.promoQuantity) return i18next.t('cartOffCanvas.shippingFreeInfoQuantity', {quantity: lineItem.promoQuantity});
            if (lineItem.promoAmount) return i18next.t('cartOffCanvas.shippingFreeInfoAmount', {amount: lineItem.promoAmount});
        }
        if (lineItem.promoQuantity && lineItem.promoQuantity === 1) return i18next.t('cartOffCanvas.giftInfo');
        if (lineItem.promoQuantity) return i18next.t('cartOffCanvas.giftInfoQuantity', {quantity: lineItem.promoQuantity});
        if (lineItem.promoAmount) return i18next.t('cartOffCanvas.giftInfoAmount', {amount: lineItem.promoAmount});

        return i18next.t('cartOffcanvas.giftInfo');
    }

    if (!list) return <></>

    return (
        <>
            <div className={"d-inline-flex flex-row align-items-center mb-3 ps-3 pe-3 pt-1 pb-1 rounded-2"}
                 role={"button"}
                 onClick={onClick}
                 style={{background: "#E3F2F6"}}>
                {!lineItem.promoShippingFree && (
                    <i className="fa-sharp fa-light fa-gift fs-9 me-2" style={{color: "#1D8197"}}></i>)}
                <span className={"fs-9"} style={{color: "#1D8197"}}>{infoText()}</span>
                <i className="fa-solid fa-arrow-right ms-2" style={{color: "#1D8197"}}></i>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div className={"d-flex flex-row justify-content-end"}>
                        <i className="fa-sharp fa-regular fa-xmark fs-6" onClick={handleClose} role={"button"}/>
                    </div>
                    <h1 className={"fs-4"}>{i18next.t('giftModal.title').toString()}</h1>

                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        {list.map((item: any, index: number) => (
                            <CartOffCanvasGiftItem key={item.id}
                                                   item={item}
                                                   className={`${index < (list.length - 1) && 'border-bottom'}`}/>
                        ))}
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}
import LoginSection from "./LoginSection";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import {closeOverlay} from "../../stores/loginOverlay/loginOverlaySlice";

export default function LoginOverlay() {
    const isOpen = useSelector((state: State) => state.loginOverlayState.isOpen);
    const dispatch = useDispatch();

    function close() {
        dispatch(closeOverlay())
    }

    if (!isOpen) return <></>

    return (
        <>
            <div className="modal-backdrop fade show" onClick={() => close()}></div>
            <div className="modal-container position-fixed top-50 start-50 translate-middle p-4 rounded-4">
                <div className="modal-header">
                    <h5 className="modal-title">Login</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => close()}/>
                </div>
                <hr className={"primary-color"}/>
                <LoginSection isOverlay={true}/>
            </div>
        </>
    )
}
import React from "react";
import HeaderAccount from "./Account";
import OffcanvasCart from "../../cart/Offcanvas";
import WishlistOffcanvas from "../../wishlist/Offcanvas";
import ProductCompareOffCanvas from "../../product/compare/OffCanvas";

export default function HeaderNavigation() {
    return (
        <>
            <div className="row g-0 w-100 header-icons">
                <ProductCompareOffCanvas/>
                <WishlistOffcanvas/>
                <HeaderAccount/>
                <OffcanvasCart/>
            </div>
        </>
    )
}
import {BlockProps} from "./BlockProps";
import Link from "../../link/Link";
import i18next from "i18next";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useDispatch} from "react-redux";
import {convertBxAttributes} from "../../../utils/bxAttributes";
import {closeSearch, setQuery} from "../../../stores/search/searchSlice";

export default function AutocompleteText({ block }: BlockProps) {
    const dispatch = useDispatch();

    const { suggestion } = block['bx-acQuery'];
    return <li {...convertBxAttributes(block, 'mb-1')}>
        <div className="d-flex align-items-stretch">
            <Link className="d-flex align-items-center flex-grow-1"
                  href={`/${i18next.language}/q/${encodeURIComponent(suggestion)}`}
                  onClick={() => dispatch(closeSearch())}>
                {suggestion}
            </Link>
            <button className="btn btn-link px-2 py-1"
                onClick={() => dispatch(setQuery(suggestion))}>
                <FontAwesomeIcon style={{ transform: "rotate(45deg)" }} icon={faArrowLeft} />
            </button>
        </div>
    </li>;
}

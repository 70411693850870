import React from "react";
import i18next from "i18next";
import $ from "jquery";
import {unserialize} from "php-serialize";

export default class SeoText extends React.Component<any, any> {
    object: any;

    constructor(props: any, context: any) {
        super(props, context);
        this.object = this.props.object;
        this.parseTranslation();
    }

    componentDidMount() {
        this.close();
    }

    parseTranslation() {
        try {
            this.object.t = unserialize(this.object.translation);
        }
        catch(e) {}
    }

    getDescription() {
        try {
            return this.object.t.description.replace(/&nbsp;/g, ' ').trim();
        }
        catch(e) {
            return this.getDefaultDescription();
        }
    }

    getDefaultDescription() {
        try {
            return this.object.description.replace(/&nbsp;/g, ' ').trim();
        }
        catch(e) {
            return '';
        }
    }

    open() {
        $('.read-more').height('auto');
        $('.read-more').addClass('open');
        $('.read-more-toggle-open').hide();
        $('.read-more-toggle-close').show();
    }

    close() {
        $('.read-more').height('200px');
        $('.read-more').removeClass('open');
        $('.read-more-toggle-open').show();
        $('.read-more-toggle-close').hide();
    }

    render() {
        if (!this.object || !this.object.description || !this.object.description.length) return <></>

        return (
            <div className={"border-top"}>
                <div className="container mt-4 seo-text read-more">
                    {(this.object.title || this.object.name) && (
                        <h2 className="mb-4">{this.object.title ?? this.object.name}</h2>
                    )}

                    <div dangerouslySetInnerHTML={{__html: this.getDescription()}}/>
                </div>
                <div className={"container"}>
                    <div className="read-more-toggles mb-5">
                        <div className="read-more-toggle-open" onClick={() => this.open()}>
                            <span
                                className={"text-decoration-underline"}>{i18next.t('general.readMore').toString()}</span>
                            <i className="fa-solid fa-circle-chevron-down ps-2"></i>
                        </div>
                        <div className="read-more-toggle-close" onClick={() => this.close()}>
                            <span className={"text-decoration-underline"}>{i18next.t('general.close').toString()}</span>
                            <i className="fa-solid fa-circle-chevron-up ps-2"></i>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
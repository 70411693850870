import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import i18next from "i18next";
import FooterShare from "../Share";
import React from "react";

export default function FooterAccordionShare() {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                sx={{background: "#000", pt: "15px", border: "1px solid #fff"}}
                id="panel5a-header">
                <p className="text-md-end">{i18next.t('footer.share').toString()}</p>
            </AccordionSummary>
            <AccordionDetails sx={{background: "#000", border: "1px solid #fff", borderTop: "none"}}>
                <FooterShare/>
            </AccordionDetails>
        </Accordion>
    )
}
import bxClient from "./bxClient";
import {getCookie} from "cookies-next";
import {v4 as uuidv4} from "uuid";
import i18next from "i18next";

class BxSearch {
  async search(query: string, signal?: AbortSignal) {
    const sessionId = getCookie('cems') ?? uuidv4();
    const profileId = getCookie('cemv') ?? uuidv4();
    let customerId: any = getCookie('customerId') ?? profileId;

    let data: any = bxClient.buildBaseData(
      i18next.language,
      sessionId.toString(),
      profileId.toString(),
      'autocomplete',
      '',
      navigator.userAgent,
      location.href,
      location.href,
      6,
      {},
      [],
      [],
      0,
      customerId,
    );

    delete data.returnFields;
    delete data.apiSecret;

    data = {
      ...data,
      query: query,
      queries: [
        {name: 'brand', minHitcount: 4},
        {name: 'categories', minHitcount: 4},
        {name: 'property_43_produktlinie', minHitcount: 4},
      ],
      acQueriesHitCount: 6
    }

    const result: any = await bxClient.post(data, undefined, signal);
    if (result.status !== 200) return;
    return result.data;
  }
}

const bxSearch = new BxSearch();
export default bxSearch

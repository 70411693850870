import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#323232",
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "14px"
                }
            },
        },
    }
});

export default function MuiThemeProvider({children}: any) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}
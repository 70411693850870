import {InspectionData} from "../../services/boxalino/bxClient";
import ClipboardButton from "../button/ClipboardButton";
import {useState} from "react";

interface Props {
    inspectionData: InspectionData[];
}

function InspectionItem({ url, requestJson, responseJson, measuredTime, boxalinoTime }: InspectionData) {
    const [urlCopied, setUrlCopied] = useState(false);
    const [requestCopied, setRequestCopied] = useState(false);
    const [responseCopied, setResponseCopied] = useState(false);

    function reset() {
        setUrlCopied(false);
        setRequestCopied(false);
        setResponseCopied(false);
    }

    return <>
        <div className="mb-2">
            <h3>URL:</h3>
            <ClipboardButton content={url} onSuccess={() => {
                reset();
                setUrlCopied(true);
            }}>
                📋 Copy URL
            </ClipboardButton>
            {urlCopied && <span className="ms-2">✅ URL copied to Clipboard</span> }
            <pre className="p-2 bg-light">{url}</pre>
        </div>
        <div className="mb-2">
            <h3>Timing</h3>
            <div>Time measured by Boxalino: {boxalinoTime}</div>
            <div>Time measured by us: {measuredTime}</div>
        </div>
        <div className="mb-2">
            <h3>Request</h3>
            <ClipboardButton className="mb-2" content={requestJson} onSuccess={() => {
                reset();
                setRequestCopied(true);
            }}>
                📋 Copy Request JSON
            </ClipboardButton>
            {requestCopied && <span className="ms-2">✅ Request copied to Clipboard</span> }
            <pre className="p-2 bg-light">{requestJson}</pre>
        </div>
        <div className="mb-4">
            <h3>Response</h3>
            <ClipboardButton className="mb-2" content={responseJson} onSuccess={() => {
                reset();
                setResponseCopied(true);
            }}>
                📋 Copy Response JSON
            </ClipboardButton>
            {responseCopied && <span className="ms-2">✅ Response copied to Clipboard</span>}
            <pre style={{ maxHeight: '90vh', overflow: 'auto' }} className="p-2 bg-light">{responseJson}</pre>
        </div>
    </>;
}

export default function BxInspect({ inspectionData }: Props) {
    return (<div className="container-fluid">
        <div className="row">
            <div className="col">
                <h1>Boxalino Request Inspection</h1>
            </div>
        </div>
        {inspectionData.map((item, index) => (
            <div key={index} className="row my-2">
                <div className="col">
                    <h2>Request {index}</h2>
                    <InspectionItem {...item} />
                </div>
            </div>
        ))}
    </div>);
}

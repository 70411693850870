import bxSearch from "../boxalino/bxSearch";

class SearchService {
  async search(query: string, signal?: AbortSignal) {
    return bxSearch.search(query, signal);
  }
}

const searchService = new SearchService();
export default searchService

import React, {useEffect, useState} from "react";

type Props = Omit<React.ComponentProps<'button'>, 'onClick'> & {
    content: string;
    onSuccess?: () => void;
}

export default function ClipboardButton({ content, onSuccess, ...otherProps }: Props) {
    const [clipboardAvailable, setClipboardAvailable] = useState(false);

    useEffect(() => {
        setClipboardAvailable(typeof navigator.clipboard?.writeText === 'function');
    }, []);

    return clipboardAvailable
        ? <button {...otherProps} onClick={async () => {
            await navigator.clipboard.writeText(content);
            onSuccess?.();
        }} />
        : <></>;
}

import React from "react";
import Image from "next/image";

export default function FooterPaymentMethods() {
    return (
        <div className={"mt-3 mt-xl-0"}>
            <div className="d-flex flex-row flex-wrap gap-2">
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <Image src={"/images/twint.png"} height={40} width={40} alt={"Twint"} className={"position-absolute top-50 start-50 translate-middle"}/>
                </div>
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <Image src={"/images/postfinance.png"} height={40} width={40} alt={"Post Finance"} className={"position-absolute top-50 start-50 translate-middle"}/>
                </div>
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <i className="fa-sharp fa-solid fa-file-invoice-dollar position-absolute top-50 start-50 translate-middle" style={{fontSize: "24px"}}></i>
                </div>
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <i className="fa-sharp fa-solid fa-building-columns position-absolute top-50 start-50 translate-middle" style={{fontSize: "24px"}}></i>
                </div>

                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <i className="fa-brands fa-cc-visa d-inline-block position-absolute top-50 start-50 translate-middle" style={{fontSize: "24px"}}></i>
                </div>
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <i className="fa-brands fa-cc-mastercard d-inline-block position-absolute top-50 start-50 translate-middle" style={{fontSize: "24px"}}></i>
                </div>
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <i className="fa-brands fa-cc-amex d-inline-block position-absolute top-50 start-50 translate-middle" style={{fontSize: "24px"}}></i>
                </div>
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <i className="fa-brands fa-cc-paypal d-inline-block position-absolute top-50 start-50 translate-middle" style={{fontSize: "24px"}}></i>
                </div>
                <div className={"border no-hover rounded-1 p-1 text-center align-middle position-relative"} style={{height: "50px", width: "80px"}}>
                    <i className="icon-pfhag-maestro d-inline-block position-absolute top-50 start-50 translate-middle" style={{fontSize: "24px"}}></i>
                </div>
            </div>
        </div>
    )
}
import * as React from 'react';
import {useState} from 'react';
import {alpha, styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons';
import i18next from "i18next";
import {useDispatch} from "react-redux";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import messageService from "../../services/message/messageService";
import MergeIcon from '@mui/icons-material/Merge';
import {ArrowLeftIcon} from "@mui/x-date-pickers/icons";
import wishlistService from "../../services/wishlistService";
import {setLists} from "../../stores/wishlist/wishlistSlice";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

interface Props {
    list: any,
    lists: any,
    setEdit: any,
    removeList: any
}

export default function WishlistMenu({list, lists, setEdit, removeList}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open: boolean = Boolean(anchorEl);
    const dispatch = useDispatch();
    const [menuState, setMenuState] = useState('default');
    const filteredMergeList = lists.filter((l: any) => l.id !== list.id);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function mergeList(sourceId: number, targetId: number) {
        const data = await wishlistService.mergeList(sourceId, targetId);
        dispatch(setLists(data));
        setMenuState('default');
    }

    const share = async () => {
        await navigator.clipboard.writeText(`${process.env.NEXT_PUBLIC_APP_URL}/wl/${list.sessionId}`);
        messageService.success(i18next.t('share.linkCopySuccess'), dispatch);
    }

    return (
        <div className={"ms-3"}>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                sx={{
                    backgroundColor: '#fff',
                    border: '1px solid #000',
                    borderRadius: '1rem',
                    padding: '5px 10px',
                    width: 'auto',
                    minWidth: 'auto',
                    '&:hover': {
                        backgroundColor: '#fff',
                    }
                }}
                disableElevation
                onClick={handleClick}>
                <FontAwesomeIcon icon={faEllipsisVertical}/>
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {menuState === 'default' && (
                    <>
                        <MenuItem onClick={share} disableRipple>
                            <ShareIcon/>
                            {i18next.t('general.share').toString()}
                        </MenuItem>
                        <Divider sx={{my: 0.5}}/>
                        <MenuItem onClick={setEdit} disableRipple>
                            <DriveFileRenameOutlineIcon/>
                            {i18next.t('general.rename').toString()}
                        </MenuItem>
                        <Divider sx={{my: 0.5}}/>
                        <MenuItem onClick={() => setMenuState('merge')} disableRipple>
                            <MergeIcon/>
                            {i18next.t('general.merge').toString()}
                        </MenuItem>
                        <Divider sx={{my: 0.5}}/>
                        <MenuItem onClick={removeList} disableRipple>
                            <DeleteIcon/>
                            {i18next.t('general.remove').toString()}
                        </MenuItem>
                    </>
                )}

                {menuState === 'merge' && (
                    <>
                        <MenuItem onClick={() => setMenuState('default')} disableRipple>
                            <ArrowLeftIcon/>
                            {i18next.t('general.back').toString()}
                        </MenuItem>
                        <Divider sx={{my: 0.5}}/>
                        {filteredMergeList.map((l: any) => (
                            <MenuItem onClick={() => mergeList(list.id, l.id)} disableRipple>
                                <span>{l.name}</span>
                            </MenuItem>
                        ))}
                    </>
                )}
            </StyledMenu>
        </div>
    );
}
import i18next from "i18next";
import RoutePrefix from "../route/prefix";
import type {NextRouter} from "next/router";
import single from "../../utils/single";

class BxProduct {
  getProduct(data: any, bxAttributes: any) {
    try {
      return {
        id: Number(single(data.products_group_id)),
        detailId: Number(single(data.id)),
        mainDetailId: Number(single(data.main_detail_id)),
        orderNumber: single(data.sku),
        name: single(data.name ?? data.title ?? data.bq_std_title_unprocessed),
        brand: single(data.brand),
        cover: single(data.image) ?? data.gallery[0],
        images: data.gallery,
        price: data.price ?? single(data.discountedPrice) ?? 0,
        pseudoPrice: data.pseudoPrice ?? single(data.selected_list_price) ?? 0,
        reviews: data.productsVote ?? 0,
        stock: Number(data.instock),
        lastStock: Number(data.laststock),
        entity: 'product',
        'bx-attributes': bxAttributes,
        additionaltext: data.additionaltext ?? '',
        review: Math.floor(data.vote * 2 + 0.5) / 2
      }
    }
    catch(e: any) {
      console.error('error parse bx product', e, data, bxAttributes);
      return {}
    }
  }

  async showAllProducts(router: NextRouter, advanced: any, product: any, query: string, locale: string) {
    if (advanced.redirectUrl?.startsWith(`/${locale}`)) {
      return router.push(advanced.redirectUrl)
      // return legacyService.redirect(advanced.redirectUrl, router, locale);
    }

    if (product) {
      // return await router.push(`/${i18next.language}/${RoutePrefix.product}/${slugify(product.name.toLowerCase())}-${product.detailId}`);
      return router.push(`/${i18next.language}/${product.link}`);
    }

    return await router.push(`/${i18next.language}/${RoutePrefix.search}/${encodeURIComponent(query)}`);
  }

  flat(products: any) {
    try {
      return products.flat();
    }
    catch(e) {
      return products;
    }
  }
}

const bxProduct = new BxProduct();
export default bxProduct

import {BlockProps} from "./BlockProps";
import {convertBxAttributes} from "../../../utils/bxAttributes";
import Link from "../../link/Link";
import single from "../../../utils/single";
import Image from "next/image";
import RatingStar from "../../rating/Star";
import {useDispatch} from "react-redux";
import {closeSearch} from "../../../stores/search/searchSlice";
import {roundPrice} from "../../../utils/roundPrice";
import {getLink} from "../../../utils/bxProductLink";

export default function AutocompleteProduct({block}: BlockProps) {
    const dispatch = useDispatch();

    const bxHit = block['bx-hit'];
    const name = single(bxHit.title);
    const rating = single(bxHit.vote);
    const pseudoPrice = Number(single(bxHit.selected_list_price));
    const price = Number(single(bxHit.discountedPrice));

    function getImage() {
        return single(bxHit.image) ?? bxHit.gallery[0];
    }

    return <li {...convertBxAttributes(block)}>
        <Link href={getLink(bxHit)} className="row g-3 mb-3" onClick={() => dispatch(closeSearch())}>
            <div className="col-2">
                <div className="d-flex align-items-center w-100" style={{aspectRatio: 1}}>
                    <Image src={getImage()} width={65} height={65} alt={name} className="w-auto h-auto mw-100 mh-100 d-block mx-auto" />
                </div>
            </div>
            <div className="col-6 col-md-7">
                <div className="fs-8 fw-bold">{name}</div>
                <RatingStar rating={Number(rating)} total={Number(single(bxHit.vote_count))} showRating={false}/>
            </div>
            <div className="col-4 col-md-3 text-end">
                {pseudoPrice > price &&
                    <div className="text-decoration-line-through fs-8">{roundPrice(pseudoPrice)}</div>
                }
                <div className="fw-bold fs-8">{roundPrice(price)}</div>
            </div>
        </Link>
    </li>;
}

import {useRouter} from "next/router";
import React from "react";
import DropdownQuantity from "../dropdown/Quantity";
import cartService from "../../services/cartService";
import {setCart, setOffCanvas, setReload} from "../../stores/cart/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import {setPremiums} from "../../stores/premium/premiumSlice";
import {setSelectedQuantity} from "../../stores/product/productSelectorSlice";
import {State} from "../../stores/store";
import tagManager from "../../services/tagManager";

export default function CartItemQuantity({lineItem, offcanvas = false, colMd, mt, col}: any) {
    const customer: any = useSelector((state: State) => state.customerState.customer);
    const cart = useSelector((state: State) => state.cartState.cart);
    const router = useRouter();
    const isConfirm = router.pathname.includes('/confirm');
    const dispatch = useDispatch();

    async function onQuantityChange(quantity: number) {
        if (quantity <= 0) return;

        await cartService.changeQuantity(lineItem.id, quantity);
        await loadCart();
        dispatch(setSelectedQuantity(1));
        const q = Math.abs(quantity - lineItem.quantity);
        lineItem.quantity < quantity ? tagManager.addToCart([lineItem], q) : tagManager.removeFromCart(lineItem, q);
    }

    async function loadCart() {
        if (offcanvas) return dispatch(setOffCanvas({isOpen: true, isLoading: true}));

        const data: any = await cartService.getCart(customer.shippingMethodId, customer.paymentMethodId);
        tagManager.pushCart(data.cart.items);
        dispatch(setCart(data.cart))
        dispatch(setPremiums([data.premiums, data.premiumVisible]))
    }

    return (
        <>
            {(isConfirm || offcanvas) ? (
                <>
                    <div className={`${col ?? 'col-4'} ${colMd ?? 'col-md-1'} d-block ${mt && 'mt-3 mt-md-0'}`}>
                        <DropdownQuantity quantity={lineItem.quantity}
                                          offcanvas={offcanvas}
                                          lineItem={lineItem}
                                          quantityChange={(quantity: number) => onQuantityChange(quantity)}
                                          mode={lineItem.mode}/>
                    </div>
                </>
            ) : (
                <div className={`${col ?? 'col-4'} ${colMd ?? 'col-md-1'} text-end ${mt && 'mt-3 mt-md-0'}`}>
                    <span className="fw-bold">{lineItem.quantity}x</span>
                </div>
            )}
        </>
    )
}
import {BlockProps} from "./BlockProps";
import Link from "../../link/Link";
import i18next from "i18next";
import single from "../../../utils/single";
import {closeSearch} from "../../../stores/search/searchSlice";
import {useDispatch} from "react-redux";

export default function AutocompleteBlogLink({block}: BlockProps) {
    const dispatch = useDispatch();

    const hitCount = (block['bx-hitCount'] ?? block['bx-hits']).totalHitCount;
    if (!hitCount) {
        return <></>;
    }

    const query = single(block.query);
    return <Link
        href={`/${i18next.language}/q/bl/${encodeURIComponent(query)}`}
        onClick={() => dispatch(closeSearch())}
        className="btn btn-alt w-100 mt-2 mb-2 rounded-5 text-center pt-2 pb-2"
    >
        <span className="me-2">{i18next.t('search.showAllBlog').toString()}</span>
        <span className="me-2 fw-normal">({hitCount} {i18next.t('search.hits').toString()})</span>
    </Link>;
}

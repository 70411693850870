import Link from "../../link/Link";
import i18next from "i18next";
import React from "react";

export default function HeaderAccount() {
    return (
        <div className="col-3">
            <Link href={`/${i18next.language}/account/overview`} aria-label={"Account"} passHref className={"persist"}>
        <span className="d-block text-center">
          <i className="fa-solid fa-user"></i>
          <p className="sup-2 d-none d-md-block">{i18next.t('general.account').toString()}</p>
        </span>
            </Link>
        </div>
    )
}
import React from "react";
import NavigationMainDrawer from "./main/Drawer";

export default function NavigationMain({navigation}: any) {
    return (
        <div className='container g-0'>
            <ul className='nav-header nav col-12 col-md-auto mb-2 justify-content-center mb-3 d-none d-lg-flex fw-bold'>
                {navigation.map((category: any) => (
                    <NavigationMainDrawer key={`nav-main-${category.id}`} category={category}/>
                ))}
            </ul>
        </div>
    )
}
import React from "react";
import Image from "next/image";
import i18next from "i18next";
import imageService from "../../../../services/product/imageService";
import Link from "next/link";
import {tree} from "../../../../utils/tree";

interface Props {
    item: any;
    className?: string
}

export default function CartOffCanvasGiftItem({item, className = ''}: Props) {
    function getCover(product: any) {
        if (product.cover) return product.cover;
        return imageService.getImage(product.imgUrls, product.orderNumber)
    }

    const renderTitle = () => {
        if (item.gift.shippingFree) {
            if (item.gift.quantity) return i18next.t('cartOffCanvas.shippingFreeInfoQuantity', {quantity: item.gift.quantity});
            if (item.gift.amount) return i18next.t('cartOffCanvas.shippingFreeInfoAmount', {amount: item.gift.amount});
        }
        if (item.gift.quantity) return i18next.t('cartOffCanvas.giftInfoQuantity', {quantity: item.gift.quantity});
        if (item.gift.amount) return i18next.t('cartOffCanvas.giftInfoAmount', {amount: item.gift.amount});
    }

    const renderEligibleItems = () => {
        return item.rules.map((rule: any) => {
            if ((rule.type === 4 && rule.path.includes(`|${tree(i18next.language)}|`)) || [1,2,3].includes(rule.type)) {
                return (
                    <Link href={`/${i18next.language}/${linkType(rule)}`}
                          target={"_blank"}
                          className={"text-decoration-underline fst-italic d-block mb-2"}>
                        <span>
                            {i18next.t('cartOffcanvas.eligibleText').toString()} ({rule.name})
                        </span>
                    </Link>
                )
            }
            return <></>
        })
    }

    const linkType = (rule: any) => {
        switch (rule.type) {
            case 1:
                return `/p/0-${rule.valueId}`;
            case 2:
                return `/b/0-${rule.valueId}`
            case 3:
                return `/pl/0-${rule.valueId}`
            case 4:
                return `/l/0-${rule.valueId}`
        }
    }

    const renderDescription = () => {
        return (
            <>
                {(item.gift.description && item.gift.description.length) ? (
                    <p>{item.gift.description}</p>
                ) : (
                    <ul className={"ms-0 list-unstyled ps-0 ms-0"}>
                        {renderFallBackRules()}
                    </ul>
                )}

                {item.gift.product ? (
                    <p>{item.gift.product.name}</p>
                ) : <></>}

                {renderEligibleItems()}
            </>
        )
    }

    const renderFallBackRules = () => {
        return (
            <>
                {(item.rules.map((rule: any) => (
                    <li key={`fallback-${rule.id}`}>
                        {(rule.type === 1) && (
                            <>
                            <span dangerouslySetInnerHTML={{
                                __html: i18next.t('giftRuleNew.productRule', {
                                    article: rule.name
                                }).toString()
                            }}/>
                            </>
                        )}
                        {(rule.type === 2) && (
                            <>
                                <span dangerouslySetInnerHTML={{
                                    __html: i18next.t('giftRuleNew.brandRule', {
                                        brand: rule.name,
                                    }).toString()
                                }}/>
                            </>
                        )}
                        {(rule.type === 3) && (
                            <>
                                <span dangerouslySetInnerHTML={{
                                    __html: i18next.t('giftRuleNew.productLineRule', {
                                        productLine: rule.name
                                    }).toString()
                                }}/>
                            </>
                        )}
                        {(rule.type === 4) && (
                            <>
                                <span dangerouslySetInnerHTML={{
                                    __html: i18next.t('giftRuleNew.categoryRule', {
                                        category: rule.name
                                    }).toString()
                                }}/>
                            </>
                        )}
                    </li>
                )))}
            </>
        )
    }

    return (
        <div key={item.gift.id} className={`pt-3 pb-3 h-100 mb-3 ${className}`}>
            <p className={"fw-bold fs-6"}>{renderTitle()}</p>

            {item.gift.shippingFree ? (
                <>{renderDescription()}</>
            ) : (
                <div className={"row"}>
                    <div className={"col-8"}>
                        {renderDescription()}
                    </div>
                    <div className={"col-4"}>
                        <Image src={getCover(item.gift.product)}
                               height={100}
                               width={100}
                               alt={`${i18next.t('gift.imgAlt').toString()} ${item.gift.product.name}`}
                               style={{maxWidth: "90px"}}
                               className={"w-100 h-auto"}/>
                    </div>
                </div>
            )}
        </div>
    )
}

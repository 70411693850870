class ProductTranslationService {
    getName(product: any) {
        try {
            if (product.nameTranslation && product.nameTranslation.length) return product.nameTranslation;
            if (Array.isArray(product.coreTranslation)) return product.name;
            if (product.coreTranslation.txtArtikel) return product.coreTranslation.txtArtikel;
            return product.name;
        }
        catch(e) {
            return product.name;
        }
    }

    getShortName(product: any) {
        try {
            if (product.shortName) return product.shortName;
            if (product.nameTranslation && product.nameTranslation.length) return product.nameTranslation.replace(`${product.brand} - `, '');
            if (Array.isArray(product.coreTranslation)) return product.name.replace(`${product.brand} - `, '');
            if (product.coreTranslation.txtArtikel) return product.coreTranslation.txtArtikel.replace(`${product.brand} - `, '');
            return product.name.replace(`${product.brand} - `, '');
        }
        catch(e) {
            return product.name.replace(`${product.brand} - `, '');
        }
    }

    getDescription(product: any) {
        try {
            // if (product.descriptionLongTranslation && product.descriptionLongTranslation.length) return product.descriptionLongTranslation;
            if (Array.isArray(product.coreTranslation)) return product.descriptionLong;
            if (product.coreTranslation.txtlangbeschreibung) return product.coreTranslation.txtlangbeschreibung;
            return product.descriptionLong;
        }
        catch(e) {
            return product.descriptionLong;
        }
    }

    getDescriptionShort(product: any) {
        try {
            if (product.descriptionTranslation && product.descriptionTranslation.length) return product.descriptionTranslation;
            if (product.coreTranslation.txtshortdescription) return product.coreTranslation.txtshortdescription;
            return product.description;
        }
        catch(e) {
            return product.description;
        }
    }
}

const productTranslationService = new ProductTranslationService();
export default productTranslationService
import AutocompleteAside from "./Aside";
import single from "../../../utils/single";
import React from "react";
import AutocompleteMain from "./Main";
import {BlockProps} from "./BlockProps";
import AutocompleteTextList from "./TextList";
import AutocompleteText from "./Text";
import AutocompleteBlogLink from "./BlogLink";
import AutocompleteProductLink from "./ProductLink";
import AutocompleteProductList from "./ProductList";
import AutocompleteProduct from "./Product";

interface Props {
    blocks: any[];
    narrative: any;
}

const components: Record<string, React.ComponentType<BlockProps>|undefined> = {
    AutocompleteAside,
    AutocompleteMain,
    AutocompleteTextList,
    AutocompleteText,
    AutocompleteProductList,
    AutocompleteProduct,
    AutocompleteProductLink,
    AutocompleteBlogLink,
};

function Block(props: BlockProps) {
    const name = single(props.block.name);
    const Component = components[name];

    if (!Component) {
        console.error(`Unknown block component ${name}`);
        return <></>;
    }

    return <Component {...props} />;
}

export default function BxAutocompleteBlocks({ narrative, blocks }: Props)  {
    return <>
        {blocks.map((block, index) =>
            <Block key={index} narrative={narrative} block={block}>
                <BxAutocompleteBlocks narrative={narrative} blocks={block.blocks}/>
            </Block>
        )}
    </>;
}

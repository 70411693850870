import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import i18next from "i18next";
import React from "react";

export default function PrimaryLoadingButton(
    {
        onClick,
        loading,
        label = i18next.t('general.send').toString(),
        data,
        disabled = false,
        className = 'pt-2 pb-2 ps-3 pe-3',
        icon = <SendIcon sx={{fill: "white !important"}}/>,
        loadingPosition = 'end',
        type = 'submit'
    }: any) {
    return (
        <>
            <LoadingButton
                size="small"
                type={type}
                onClick={onClick}
                endIcon={icon}
                loading={loading}
                loadingPosition={loadingPosition}
                fullWidth
                disabled={disabled}
                data-bs-toggle={data ? data.bsToggle : null}
                data-bs-target={data ? data.bsTarget : null}
                variant="contained">
                <span className={`text-white ${className}`} style={{textTransform: 'initial'}}>
                  {label}
                </span>
            </LoadingButton>
        </>
    )
}
import Link from "../link/Link";
import i18next from "i18next";

interface Props {
    config: any,
    visible: boolean
}

export default function TeaserAd({config, visible}: Props) {
    if (!visible) return <></>

    return (
        <Link href={config.link} style={{display: "block"}}>
            <div className={"text-white p-2 bg-primary"}>
                <div className={`text-center container ${config.animation && 'animation-pulse'}`} style={{animationIterationCount: config.iteration}}>
                    <span className={"label"}>{config.text}</span>
                    <i className="ms-2 fa-regular fa-up-right-from-square"></i>
                </div>
            </div>
        </Link>
    )
}
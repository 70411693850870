import SearchInput, {Ref} from "./Input";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import React, {useEffect, useRef} from "react";
import {setOffCanvas, setResult, setLoading, setLastQuery} from "../../stores/search/searchSlice";
import searchService from "../../services/product/searchService";

export default function Search() {
    const query = useSelector((state: State) => state.searchState.query);
    const lastQuery = useSelector((state: State) => state.searchState.lastQuery);
    const offCanvas = useSelector((state: State) => state.searchState.offCanvas);
    const result = useSelector((state: State) => state.searchState.result);
    const dispatch = useDispatch();
    const inputRef = useRef<Ref>(null);

    async function search(query: string, abortSignal: AbortSignal) {
        try {
            const searchResult = await searchService.search(query, abortSignal);
            if (searchResult) {
                dispatch(setResult(searchResult));
                dispatch(setLoading(false));
                dispatch(setLastQuery(query));
                inputRef.current?.blur();
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (!offCanvas) return;
        const abortController = new AbortController();
        if (!result) {
            dispatch(setLoading(true));
            void search(query, abortController.signal);
            return () => abortController.abort();
        } else if (query !== lastQuery) {
            dispatch(setLoading(true));
            const delayDebounceFn = setTimeout(() => search(query, abortController.signal), 250);
            return () => {
                clearTimeout(delayDebounceFn);
                abortController.abort();
            };
        }
    }, [query, lastQuery, offCanvas, result]);

    function onFocus() {
        dispatch(setOffCanvas(true));
    }

    return <SearchInput onFocus={onFocus} ref={inputRef}/>;
}

import * as React from 'react';
import {useState} from 'react';
import {alpha, styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons';
import i18next from "i18next";
import cartService from "../../../services/cartService";
import wishlistService from "../../../services/wishlistService";
import {closeWishlist, setLists} from "../../../stores/wishlist/wishlistSlice";
import {openCart} from "../../../stores/cart/cartSlice";
import {useDispatch} from "react-redux";
import {ArrowLeftIcon} from '@mui/x-date-pickers/icons';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

interface Props {
    lineItem: any,
    lists: any
}

export default function WishlistItemMenu({lineItem, lists}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open: boolean = Boolean(anchorEl);
    const dispatch = useDispatch();
    const [menuState, setMenuState] = useState('default');

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function addItemToCart() {
        await cartService.addToCart(lineItem.orderNumber);
        await loadCart();
    }

    async function removeItem() {
        const data = await wishlistService.removeItem(lineItem.id);
        dispatch(setLists(data));
    }

    async function moveItem(listId: number) {
        const data = await wishlistService.moveItem(lineItem.id, listId);
        dispatch(setLists(data));
    }

    async function loadCart() {
        dispatch(closeWishlist());
        dispatch(openCart());
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                sx={{
                    backgroundColor: '#fff',
                    border: '1px solid #000',
                    borderRadius: '1rem',
                    padding: '5px 10px',
                    width: 'auto',
                    minWidth: 'auto',
                    '&:hover': {
                        backgroundColor: '#fff',
                    }
                }}
                disableElevation
                onClick={handleClick}>
                <FontAwesomeIcon icon={faEllipsisVertical}/>
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {menuState === 'default' && (
                    <>
                        <MenuItem onClick={addItemToCart} disableRipple>
                            <AddShoppingCartIcon/>
                            {i18next.t('cart.addTitle').toString()}
                        </MenuItem>
                        <Divider sx={{my: 0.5}}/>
                        <MenuItem onClick={removeItem} disableRipple>
                            <DeleteIcon/>
                            {i18next.t('general.remove').toString()}
                        </MenuItem>
                        <Divider sx={{my: 0.5}}/>
                        <MenuItem onClick={() => setMenuState('move')} disableRipple>
                            <DriveFileMoveIcon/>
                            {i18next.t('general.move').toString()}
                        </MenuItem>
                    </>
                )}

                {menuState === 'move' && (
                    <>
                        <MenuItem onClick={() => setMenuState('default')} disableRipple>
                            <ArrowLeftIcon/>
                            {i18next.t('general.back').toString()}
                        </MenuItem>
                        <Divider sx={{my: 0.5}}/>
                        {lists.map((list: any) => (
                            <MenuItem onClick={() => moveItem(list.id)} disableRipple>
                                <span>{list.name}</span>
                            </MenuItem>
                        ))}
                    </>
                )}
            </StyledMenu>
        </div>
    );
}
import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../stores/store";
import Link from "../../link/Link";
import apiClient from "../../../utils/apiClient";
import cartService from "../../../services/cartService";
import {setCart, setReload} from "../../../stores/cart/cartSlice";
import {setPremiums} from "../../../stores/premium/premiumSlice";
import React, {useState} from "react";
import Image from "next/image";
import imageService from "../../../services/product/imageService";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import PrimaryLoadingButton from "../../button/PrimaryLoadingButton";
import {LinearProgress} from "@mui/material";
import {roundPrice} from "../../../utils/roundPrice";

interface Props {
    className?: string
}

export default function CartOffCanvasPremium({className = ''}: Props) {
    const customer = useSelector((state: State) => state.customerState.customer);
    const premiums: any = useSelector((state: State) => state.premiumState.premiums);
    const isVisible = useSelector((state: State) => state.premiumState.isVisible);
    const [formLoading, setFormLoading] = useState(false);
    let cart = useSelector((state: State) => state.cartState.cart);
    const dispatch = useDispatch();

    async function addPremiumToCart(premium: any) {
        setFormLoading(true);
        await apiClient.post('/api/cart/add', {orderNumber: premium.orderNumber, quantity: 1});
        const data = await cartService.getCart(customer.shippingMethodId, customer.paymentMethodId);
        dispatch(setCart(data.cart))
        dispatch(setPremiums([data.premiums, data.premiumVisible]));
        dispatch(setReload(true));
        setFormLoading(false);
    }

    const breakpoints: any = {
        1: {
            slidesPerView: 1
        },
        340: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 2
        },
        1200: {
            slidesPerView: 2
        },
    }

    function getProgressBar(premium: any) {
        if (premium.startPrice > cart.total.subTotal) {
            return (
                <>
                    <LinearProgress variant="determinate" value={(cart.total.subTotal / premium.startPrice) * 100}/>
                    <div className={"mt-2 mb-2 text-center"}>
                        {i18next.t('offcanvasCartPremium.progressBarIncompleteText', {price: roundPrice(premium.startPrice - cart.total.subTotal)}).toString()}
                    </div>
                </>
            )
        }

        return (
            <>
                <LinearProgress variant="determinate" value={100}/>
                <div className={"mt-2 mb-2 fw-bold text-center"}>
                    {i18next.t('offcanvasCartPremium.progressBarComleteText').toString()}
                </div>
            </>
        )
    }

    if (!isVisible || !premiums || !premiums.length) return <></>
    if (cart.total.subTotal < (premiums[0].startPrice / 2)) return <></>

    return (
        <div className={className}>
            <b className="mt-5">{i18next.t('premium.choose').toString()}</b>
            <hr className={"primary-color mb-4"}/>

            <Swiper pagination={{clickable: true}}
                    breakpoints={breakpoints}
                    mousewheel={{forceToAxis: true}}
                    freeMode={{sticky: true}}
                    modules={[Pagination, Mousewheel, FreeMode]}
                    spaceBetween={10}
                    className="d-flex align-items-stretch pb-5 premiums-slider w-100"
                    style={{maxWidth: 'calc(100vw - 32px)'}}
                    preventClicks={true}>
                {premiums && (premiums.map((premium: any) => (
                    <SwiperSlide key={`cart-premium-${premium.articleId}`}
                                 className={"slide border border-3 d-block rounded-4 p-4"}>
                        <div className="flex-fill flex-md-grow">
                            <Link href={`/${i18next.language}/${premium.link}`}>
                                <div className={"text-center mb-4"}>
                                    <Image src={imageService.getImage(premium.imgUrls, premium.orderNumber)}
                                           width="170"
                                           height="170"
                                           className={"w-100 h-auto p-3"}
                                           alt={premium.name}/>
                                </div>
                                <p style={{height: "43px",}}
                                   className={"overflow-y-hidden mb-4 ellipsis-text"}>
                                    {premium.name}
                                </p>
                            </Link>

                            {getProgressBar(premium)}

                            <PrimaryLoadingButton
                                label={i18next.t('general.add').toString()}
                                onClick={() => addPremiumToCart(premium)}
                                className={"pt-1 pb-1 ps-3 pe-3 relative"}
                                icon={<></>}
                                loadingPosition={"center"}
                                disabled={premium.startPrice > cart.total.subTotal}
                                loading={formLoading}
                            />
                        </div>
                    </SwiperSlide>
                )))}
            </Swiper>
        </div>
    );
}
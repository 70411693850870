import single from "./single";
import i18next from "i18next";
import RoutePrefix from "../services/route/prefix";
import slugify from "../services/slugify";

export function getLink(bxHit: any) {
    const link = single(bxHit.link)
    if (link && !link.includes('/detail/index/sArticle')) {
        const url = new URL(link);
        return url.pathname + url.search;
    }

    return `/${i18next.language}/${RoutePrefix.product}/${slugify(single(bxHit.title).toLowerCase())}-${bxHit.id}`;
}

import React from "react";
import i18next from "i18next";
import slugify from "../../../services/slugify";
import Link from "next/link";

export default class FooterLink extends React.PureComponent<any, any> {
    link: any;
    action: any;
    marginEnd: any;
    marginBottom: any;

    constructor(props: any, context: any) {
        super(props, context);
        this.link = props.link
        this.action = this.link.action;
        this.marginEnd = props.marginEnd ? 'me-3' : 'me-3 me-md-0 ms-md-3';
        this.marginBottom = props.marginBottom ?? 'mb-3';

        this.state = {
            action: this.link.action
        }
    }

    componentDidMount() {
        if (this.link.action.length) return this.setState({action: this.action})
        this.setState({action: `/${i18next.language}/s/${slugify(this.link.title.toLowerCase())}-${this.link.id}`})
    }

    getLink() {
        if (this.action.length) return this.action;
        if (!this.action.length) return `/${i18next.language}/s/${slugify(this.link.title.toLowerCase())}-${this.link.id}`;
        const path = this.action.substring(1, this.action.length).split('/');
        return `/${i18next.language}/s/${slugify(path[path.length - 1].toLowerCase())}-${this.link.id}`;
    }

    render() {
        return (
            <Link href={this.getLink()} className={`d-block ${this.marginEnd} ${this.marginBottom}`}>{this.link.title}</Link>
        )
    }
}
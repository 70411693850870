import React from "react";

export default function FooterShare() {
    return (
        <div className="d-flex flex-wrap mt-3 mt-xl-0">
            <div className="pe-4 mb-3">
                <a href="https://www.pinterest.com/perfecthair/"
                   rel={"noreferrer nofollow noopener"}
                   target={"_blank"}>
                    <i className="fa-brands fa-pinterest-square fs-3"></i>
                </a>
            </div>
            <div className="pe-4 mb-3">
                <a href="http://www.linkedin.com/company/perfecthair-ag"
                   rel={"noreferrer nofollow noopener"}
                   target={"_blank"}>
                    <i className="fa-brands fa-linkedin fs-3"></i>
                </a>
            </div>
            <div className="pe-4 mb-3">
                <a href="https://www.facebook.com/PerfectHairch-136090639739076"
                   rel={"noreferrer nofollow noopener"}
                   target={"_blank"}>
                    <i className="fa-brands fa-facebook-square fs-3"></i>
                </a>
            </div>
            <div className="pe-4 mb-3">
                <a href="https://www.youtube.com/user/TeamPerfectHair"
                   rel={"noreferrer nofollow noopener"}
                   target={"_blank"}>
                    <i className="fa-brands fa-youtube fs-3"></i>
                </a>
            </div>
            <div className="pe-4 mb-3">
                <a href="https://instagram.com/perfecthairch/"
                   rel={"noreferrer nofollow noopener"} target={"_blank"}>
                    <i className="fa-brands fa-instagram fs-3"></i>
                </a>
            </div>
        </div>
    )
}
import i18next from "i18next";
import RoutePrefix from "../../services/route/prefix";
import slugify from "../../services/slugify";
import Link from "../link/Link";
import productLinkService from "../../services/product/productLinkService";

export default function CartItemLink({lineItem, children, onClick}: any) {
    return (
        <Link
            // href={`/${i18next.language}/${RoutePrefix.product}/${slugify(lineItem.name.toLowerCase())}-${lineItem.detailId}`}
            href={productLinkService.getLink(lineItem)}
            onClick={onClick}
            disableLink={![0, 1].includes(lineItem.mode)}
            role={[0, 1].includes(lineItem.mode) ? 'button' : 'none'}>
            {children}
        </Link>
    )
}
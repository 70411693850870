import {BlockProps} from "./BlockProps";
import {convertBxAttributes} from "../../../utils/bxAttributes";

export default function AutocompleteTextList({ block, children }: BlockProps) {
    if (!block['bx-acQueries'].totalHitCount) {
        return <></>;
    }

    return <>
        <div className="fw-bold">{block.title}</div>
        <hr className="mt-0 mb-1 primary-color" />
        <ul {...convertBxAttributes(block, 'list-group list-unstyled mb-2')}>
            {children}
        </ul>
    </>;
}

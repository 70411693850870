import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import i18next from "i18next";
import FooterPaymentMethods from "../PaymentMethods";
import React, {useState} from "react";

export default function FooterAccordionPaymentMethods() {
    const [expanded, setExpanded] = useState(true);

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                sx={{background: "#000", pt: "15px", border: "1px solid #fff"}}
                id="panel2a-header">
                <p>{i18next.t('footer.paymentMethods').toString()}</p>
            </AccordionSummary>
            <AccordionDetails sx={{background: "#000", border: "1px solid #fff", borderTop: "none"}}>
                <FooterPaymentMethods/>
            </AccordionDetails>
        </Accordion>
    )
}
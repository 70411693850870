import bxProduct from "../../../services/boxalino/bxProduct";
import Image from "next/image";
import {roundPrice} from "../../../utils/roundPrice";
import Link from "../../link/Link";
import i18next from "i18next";
import cartService from "../../../services/cartService";
import {useDispatch} from "react-redux";
import {setCart, setReload} from "../../../stores/cart/cartSlice";
import messageService from "../../../services/message/messageService";
import React, {useEffect, useState} from "react";
import apiInternal from "../../../utils/apiInternal";
import cookieService from "../../../services/cookie/cookieService";
import {locale} from "../../../utils/locale";
import productLinkService from "../../../services/product/productLinkService";
import {convertBxAttributes} from "../../../utils/bxAttributes";

function ProductLink({ product, children }: React.PropsWithChildren<{ product: any }>) {
    return <Link href={productLinkService.getLink(product)}>
        {children}
    </Link>
}

export default function BxCartRecommendations({ids}: any) {
    const dispatch = useDispatch();
    const [recommendations, setRecommendations]: any = useState();

    useEffect(() => {
        const fn = async () => {
            const resBxRecommendation = await apiInternal().post('/api/v2/bx/cart', {
                ids: ids,
                locale: i18next.language,
                customerGroup: cookieService.getCustomerGroup(),
                languageId: locale(i18next.language)
            });
            setRecommendations(resBxRecommendation.data);
        }
        fn().then();
    }, []);

    async function addToCart(product: any) {
        await messageService.close(dispatch);
        await cartService.addToCart(product.orderNumber);
        const resCart = await cartService.getOffcanvasCart();
        dispatch(setCart(resCart.cart));
        dispatch(setReload(true));
        return messageService.success(i18next.t('cart.titleAdded'), dispatch);
    }

    if (!recommendations) return <></>;

    const list = recommendations.blocks[0];
    if (!list.blocks.length) return <></>;

    return (
        <div {...convertBxAttributes(list, 'ps-3 pe-3 pb-4')}>
            <p className={"border-bottom fw-bold mb-4"}>{i18next.t('general.cartRecommendationTitle').toString()}</p>
            {list.blocks.map((item: any) => {
                const product = bxProduct.getProduct(item['bx-hit'], item['bx-attributes']);
                return (
                    <div key={`bx-cart-rec-${product.id}`} {...convertBxAttributes(item, 'row align-items-center mb-4')}>
                        <div className={"col-3 col-md-2"}>
                            <ProductLink product={product}>
                                <Image src={product.cover} alt={product.name} height={40} width={40}
                                       className={"w-100 h-auto"}/>
                            </ProductLink>
                        </div>
                        <div className={"col-6 col-md-7"}>
                            <ProductLink product={product}>
                                <div className="mb-1">{product.name}</div>
                                {product.additionaltext && <div className="mb-1">{product.additionaltext}</div>}
                                <div className={"fw-bold"}>{roundPrice(product.price)}</div>
                            </ProductLink>
                        </div>
                        <div className={"col-3 text-end"}>
                            <button type="button" className="btn btn-black text-white w-75 bx-basket-add"
                                    aria-label={"Add to cart"} onClick={() => addToCart(product)}>
                                <i className="fa-solid fa-cart-plus text-white"/>
                            </button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

import React, {useEffect, useRef} from "react";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import i18next from "i18next";
import apiFetch from "../../utils/apiFetch";
import {setBxData, setLoadedLanguage} from "../../stores/promoBanner/promoBannerSlice";
import {State} from "../../stores/store";
import {convertBxAttributes} from "../../utils/bxAttributes";
import single from "../../utils/single";
import {BxRichText} from "../boxalino/RichText";
import VoucherCodeCopy from "../boxalino/VoucherCodeCopy";

function BannerContent({block}: { block: any }) {
    function getValue(key: string) {
        return single(block[key]) ?? '';
    }

    const text = getValue('text');
    const link = getValue('link');
    const code = getValue('code');

    if (code) {
        return <div role="button">
            <BxRichText content={text}/>
            <span> | </span>
            <VoucherCodeCopy code={code}>
                <span className="text-decoration-underline">{i18next.t('copy.codeTitle').toString()}</span>
            </VoucherCodeCopy>
        </div>;
    } else if (link) {
        return (
            <Link href={link}>
                <BxRichText content={text}/>
                <i className="ms-2 fa-regular fa-up-right-from-square"></i>
            </Link>
        );
    } else {
        return <BxRichText content={text}/>;
    }
}

interface Props {
    lightColor?: boolean
}

export default function PromotionAdBanner({lightColor = false}: Props) {
    const dispatch = useDispatch();
    const bxData = useSelector((state: State) => state.promoBannerState.bxData);
    const loadedLanguage = useSelector((state: State) => state.promoBannerState.loadedLanguage);
    const divRef = useRef<HTMLDivElement>(null);

    const wrappingBlock = bxData?.blocks?.[0];
    const bannerBlock = wrappingBlock?.blocks[0];

    const color = single(bannerBlock?.['text-color']);
    const backgroundColor = single(bannerBlock?.['background-color']);
    let className = 'promo-banner pt-2 pb-2';
    if (bannerBlock) {
        if (!color) {
            className += lightColor ? ' text-primary' : ' text-white';
        }
        if (!backgroundColor) {
            className += lightColor ? ' bg-primary-light' : ' bg-primary';
        }
    }

    useEffect(() => {
        async function loadData() {
            dispatch(setLoadedLanguage(i18next.language));
            const response = await apiFetch('/api/v2/bx/banner', {locale: i18next.language, url: window.location.href});
            const bxData = await response.json();
            dispatch(setBxData(bxData));
        }

        if (loadedLanguage === i18next.language) {
            return;
        }
        void loadData();
    }, [loadedLanguage, i18next.language]);

    useEffect(() => {
        if (divRef.current) {
            if (color) {
                divRef.current.style.setProperty('color', color, 'important');
            }
            if (backgroundColor) {
                divRef.current.style.setProperty('background-color', backgroundColor, 'important');
            }
        }
        return () => {
            if (divRef.current) {
                divRef.current.style.removeProperty('color');
                divRef.current.style.removeProperty('background-color');
            }
        };
    }, [color, backgroundColor]);

    return <div ref={divRef} className={className}>
        <div
            className='container text-center align-middle bx-ad-banner'>
            {wrappingBlock &&
                <div {...convertBxAttributes(wrappingBlock)}>
                    {bannerBlock &&
                        <div {...convertBxAttributes(bannerBlock)}><BannerContent block={bannerBlock}/></div>}
                </div>
            }
        </div>
    </div>;
}

import i18next from "i18next";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../stores/store";
import {setOpen} from "../../../stores/bxAd/adSlice";
import slugify from "../../../services/slugify";
import Link from "next/link";
import Image from "next/image";
import {roundPrice} from "../../../utils/roundPrice";

export default function BxDetailAdsOffCanvas({data}: any) {
    const isOpen = useSelector((state: State) => state.adState.isOpen);
    const dispatch = useDispatch();

    if (!data || !data.top) return <></>
    if (!isOpen) return <></>

    function getLink(product: any) {
        try {
            let main = product.variants.find((variant: any) => variant.id === product.mainDetailId);
            if (!main && product.variants.length) main = product.variants[0];
            if (main) return main.link;
            return `${slugify(product.name.toLowerCase())}-${product.id}`;
        }
        catch(e) {
            return ``;
        }
    }

    try {
        const products = data.top[0].blocks;

        return (
            <>
                <div className="offcanvas-cart">
                    <div className="position-relative h-100">
                        <div className="position-relative">
                            <div className="ps-3 pe-3 pt-2 pb-0 mt-2" onClick={() => dispatch(setOpen(!isOpen))} role="button">
                                <i className="fa-solid fa-circle-xmark p-3"></i>
                                <span className="ms-2 fw-bold">{i18next.t('general.close').toString()}</span>
                            </div>
                            <hr className={"primary-color"}/>

                            <div className={"p-3"}>
                                {products.map((product: any) => (
                                    <div key={product.id} className={"row flex-fill g-0 p-2 mb-3"}
                                         onClick={() => dispatch(setOpen(!isOpen))}>
                                        <Link href={`/${i18next.language}/${getLink(product)}?sPartner=GoogleShoppingEN`}
                                              className={"d-flex"}>
                                            <div className={"col-4 text-center p-2"}>
                                                <Image src={product.cover}
                                                       alt={product.name}
                                                       width={200}
                                                       height={200}
                                                       style={{maxHeight: "60px"}}
                                                       sizes="(max-width: 767px) 15vw, 10vw"
                                                       className="h-100 w-auto"/>
                                            </div>
                                            <div className={"col-8 pt-1"}>
                                                <div className={"fw-bold"}>{product.name}</div>
                                                <div>{product.brand}</div>
                                                <div>{roundPrice(product.price)}</div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offcanvas-bg" onClick={() => dispatch(setOpen(!isOpen))}></div>
            </>
        )
    }
    catch(e) {
        console.error(e);
        return <></>
    }
}

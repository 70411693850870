import apiInternal from "../../utils/apiInternal";

class VariantService {
    async getVariants(products: any[], price: boolean = false, customerGroup: string, locale: string = 'de') {
        if (!products || !products.length) return;
        if (!Array.isArray(products)) return []
        const ids = products.filter((p: any) => p).map((product: any) => parseInt(product.id)).filter((i: any) => !isNaN(i));
        await this.addVariants(products, customerGroup, price, locale, ids);
    }

    async fetchVariants(ids: number[], price: boolean, customerGroup: string, locale: string) {
        const result: any = await apiInternal().post('/api/v2/product/variants', {
            ids: ids,
            price: price,
            customerGroup: customerGroup,
            locale: locale
        });
        if (result.status !== 200) return false;
        return result.data;
    }

    async addVariants(products: any, customerGroup: string, price: boolean, locale: string, ids?: number[], variants?: any) {
        if (!variants && ids) variants = await this.fetchVariants(ids, price, customerGroup, locale);
        if (!variants || !variants.length) return false;
        if (!Array.isArray(variants)) return products;

        let list: any = [];
        variants.map((variant: any) => {
            let group = list[variant.articleId] ?? [];
            group.push(variant)
            if (!group.length) return;
            list[variant.articleId] = group;
        });
        const blogs = await this.getBlogLinks(products);

        products.filter((p: any) => p).map((product: any) => {
            const blog = blogs.find((b: any) => b.id === parseInt(product.id));
            if (blog) product.link = blog.link;

            const group = list[product.id];
            product.variants = group ?? [];
            if (!group) return;

            const mainVariant = group.find((o: any) => o.id === product.detailId);
            if (!mainVariant) return;
            product.price = mainVariant.price;
            product.pseudoPrice = mainVariant.pseudoPrice;
            product.reviews = mainVariant.reviews;
            product.reviewsTotal = mainVariant.reviewTotal;
        });
    }

    async removeVariants(products: any, detailIdsToRemain: number[]) {
        products.map((variant: any) => {
            variant.variants = variant.variants.filter((v: any) => detailIdsToRemain.indexOf(v.id) !== -1);
        });
    }

    async getBlogLinks(products: any) {
        try {
            const blogEntities = products.filter((p: any) => p.entity === 'blog');
            const resBlogs: any = await apiInternal().post('/api/v2/blog/links', {ids: blogEntities.map((b: any) => Number(b.id))});
            return resBlogs ? resBlogs.data : [];
        } catch (e: any) {
            return [];
        }
    }
}

const variantService = new VariantService();
export default variantService
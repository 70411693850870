import {BlockProps} from "./BlockProps";
import {useDispatch} from "react-redux";
import Link from "../../link/Link";
import single from "../../../utils/single";
import i18next from "i18next";
import {closeSearch} from "../../../stores/search/searchSlice";

export default function AutocompleteProductLink({block, narrative}: BlockProps) {
    const dispatch = useDispatch();

    const hitCount = (block['bx-hitCount'] ?? block['bx-hits']).totalHitCount;
    if (!hitCount) {
        return <></>;
    }

    const redirect = narrative.advanced[0].redirect_url;
    const query = single(block.query);
    return <Link
        href={redirect ?? `/${i18next.language}/q/${encodeURIComponent(query)}`}
        onClick={() => dispatch(closeSearch())}
        className="btn btn-black text-white w-100 mt-md-2 mb-2 rounded-5 text-center py-2"
    >
        <span className="me-2">{i18next.t('search.showAll').toString()}</span>
        <span className="me-2 fw-normal">({hitCount} {i18next.t('search.hits').toString()})</span>
    </Link>;
}

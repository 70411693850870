import PromotionAdBanner from "./AdBanner";
import React from "react";
import TeaserAd from "./Ad";
import {useRouter} from "next/router";

interface Props {
    teaserAd: any
}

export default function AdContainer({teaserAd}: Props) {
    const router = useRouter();
    const now = new Date();
    const start = new Date(teaserAd.start);
    const end = new Date(teaserAd.end);
    const visible = teaserAd.active && (start <= now && end >= now);

    return (
        <>
            <TeaserAd config={teaserAd} visible={visible}/>
            <PromotionAdBanner key={`promo-ad-banner-${router.asPath}`} lightColor={visible}/>
        </>
    )
}
import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import {closeWishlist, openWishlist, setLists} from "../../stores/wishlist/wishlistSlice";
import {roundPrice} from "../../utils/roundPrice";
import Link from "../link/Link";
import React, {useEffect, useState} from "react";
import wishlistService from "../../services/wishlistService";
import WishlistAddForm from "./AddForm";
import cartService from "../../services/cartService";
import {openCart} from "../../stores/cart/cartSlice";
import Image from "next/image"
import imageService from "../../services/product/imageService";
import {LinearProgress, SwipeableDrawer} from "@mui/material";
import WishlistItemMenu from "./item/Menu";
import WishlistMenu from "./Menu";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {getCookie} from "cookies-next";
import productLinkService from "../../services/product/productLinkService";

export default function WishlistOffcanvas() {
    const isOpen = useSelector((state: State) => state.wishlistState.isOpen);
    const isOverlayOpen = useSelector((state: State) => state.wishlistState.isListOpen)
    const lists = useSelector((state: State) => state.wishlistState.lists);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState<any>(null);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState<string | false>('panel0');

    function handleChange(panel: string) {
        setExpanded(panel === expanded ? false : panel);
    }

    useEffect(() => {
        if (!isOpen) return;

        const fn = async () => {
            await wishlistService.cookie();
            const data = await wishlistService.load();
            dispatch(setLists(data));
            setIsLoading(false);
        };

        void fn();
    }, [isOpen, isOverlayOpen])

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            isOpen ? dispatch(closeWishlist()) : dispatch(openWishlist());
        };

    function setEdit(id: number, name: string) {
        setName(name);
        setIsEdit(true);
        setEditId(id);
    }

    function cancelEdit() {
        setIsEdit(false);
        setEditId(null);
    }

    async function updateName(id: number) {
        const data = await wishlistService.rename(id, name);
        if (data) {
            setIsEdit(false);
            setEditId(null);
            dispatch(setLists(data));
        }
    }

    async function removeList(id: number) {
        const data = await wishlistService.removeList(id);
        dispatch(setLists(data));
    }

    async function removeItem(id: number) {
        setIsLoading(true);
        const data = await wishlistService.removeItem(id);
        dispatch(setLists(data));
        setIsLoading(false);
    }

    async function addItemToCart(orderNumber: string) {
        await cartService.addToCart(orderNumber);
        await loadCart();
    }

    async function addItemsToCart(id: number) {
        await cartService.addItemsToCart(id);
        await loadCart();
    }

    async function loadCart() {
        dispatch(closeWishlist());
        dispatch(openCart());
    }

    return (
        <>
            <div className="col-3">
                <span className="d-block text-center" aria-label={"Cart"} role="button" onClick={toggleDrawer()}>
                    <i className="fa-solid fa-heart"></i>
                    <p className="sup-2 d-none d-md-block">{i18next.t('general.wishlist').toString()}</p>
                </span>
            </div>
            <SwipeableDrawer
                anchor={'right'}
                open={isOpen}
                onClose={toggleDrawer()}
                onOpen={toggleDrawer()}>
                <div className="position-relative mui-offcanvas">
                    <div className="ps-3 pe-3 pt-4 pb-3 bg-dark text-white" onClick={toggleDrawer()} role="button">
                        <i className="fa-solid fa-circle-xmark"></i>
                        <span className="ms-2 fw-bold">{i18next.t('general.close').toString()}</span>
                    </div>

                    <div className={"text-center mb-4"} style={{height: "5px"}}>
                        {isLoading && (
                            <LinearProgress color="primary"/>
                        )}
                    </div>

                    {!isLoading && (
                        <>
                            {(lists.length) ? (
                                <>
                                    {!getCookie('sanctumToken') && (
                                        <div
                                            className={"alert alert-secondary ms-2 me-2 rounded-3 p-3 mb-3 d-flex flex-row gap-3"}>
                                            <FontAwesomeIcon icon={faCircleInfo}/>
                                            <div>
                                                <p>{i18next.t('wishlist.saveListInfoText').toString()}</p>
                                                <Link href={`/${i18next.language}/login`}
                                                      onClick={toggleDrawer()}
                                                      className={"text-decoration-underline d-block"}>
                                                    <span>{i18next.t('wishlist.saveListInfoLinkText').toString()}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                    <div className="ps-2 pe-2">
                                        {lists.map((list: any, index: number) => (
                                            <Accordion
                                                key={index}
                                                expanded={expanded === `panel${index}`}
                                                sx={{
                                                    marginBottom: "0px",
                                                    border: "1px solid rgba(0,0,0,0.2)",
                                                    borderLeft: 'none',
                                                    borderRight: 'none',
                                                    boxShadow: "none",
                                                    "&:before": {height: 0}
                                                }}>
                                                <AccordionSummary
                                                    aria-controls={`panel${index}d-content`}
                                                    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}
                                                                                          onClick={() => handleChange(`panel${index}`)}/>}
                                                    sx={{
                                                        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                                        borderRadius: "10px",
                                                        p: 4,
                                                        pb: 0,
                                                        pt: 1,
                                                        boxShadow: "none",
                                                        border: "none",
                                                        background: '#fff'
                                                    }}
                                                    id={`panel${index}d-header`}>
                                                    {(isEdit && editId === list.id) ? (
                                                        <>
                                                            <input type="text"
                                                                   className="form-control w-auto accordion-action"
                                                                   value={isEdit ? name : list.name}
                                                                   onChange={(e: any) => setName(e.target.value)}/>
                                                            <button type="button"
                                                                    onClick={() => updateName(list.id)}
                                                                    className="btn btn-alt btn-icon ms-4 h-auto position-relative">
                                                                <i
                                                                    className="fa-solid fa-floppy-disk position-absolute top-50 start-50 mt-1 translate-middle"></i>
                                                            </button>
                                                            <button type="button"
                                                                    onClick={() => cancelEdit()}
                                                                    className="btn btn-alt btn-icon ms-2 h-auto position-relative">
                                                                <i
                                                                    className="fa-solid fa-xmark position-absolute top-50 start-50 mt-1 translate-middle"></i>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <div
                                                            className={"d-flex flex-row justify-content-between w-100"}>
                                                            <b onClick={() => handleChange(`panel${index}`)}
                                                               className={"flex-grow-1"}
                                                               role={"button"}>
                                                                {list.name}
                                                            </b>
                                                            <WishlistMenu list={list} lists={lists}
                                                                          setEdit={() => setEdit(list.id, list.name)}
                                                                          removeList={() => removeList(list.id)}/>
                                                        </div>
                                                    )}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {(list.items.length) ? (
                                                        <>
                                                            {(list.items.map((item: any) => (
                                                                <div key={item.id}
                                                                     className="line-item position-relative mb-3 icon-2">
                                                                    <Link
                                                                        onClick={toggleDrawer()}
                                                                        href={productLinkService.getLink(item)}>
                                                                        <div className="row p-3">
                                                                            <div className="col-3">
                                                                                <Image
                                                                                    src={imageService.getImage(item.imgUrls, item.orderNumber)}
                                                                                    width="80"
                                                                                    height="80"
                                                                                    className={"w-100 h-auto"}
                                                                                    alt=""/>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <p className={"fw-bold"}>
                                                                                    {item.name} {item.size}
                                                                                </p>
                                                                                <p>{roundPrice(item.price)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>

                                                                    <div
                                                                        className={"position-absolute mt-5 top-0 end-0"}>
                                                                        <WishlistItemMenu lineItem={item}
                                                                                          lists={lists}/>
                                                                    </div>
                                                                </div>
                                                            )))}
                                                        </>
                                                    ) : (
                                                        <p className="mt-3 ps-4 pe-4">{i18next.t('list.empty').toString()}.</p>
                                                    )}

                                                    {(list.items.length) ? (
                                                        <button
                                                            className="btn btn-black text-white w-100 mt-3 mb-3"
                                                            onClick={() => addItemsToCart(list.id)}>
                                                            {i18next.t('wishlist.addToCartList').toString()}
                                                        </button>
                                                    ) : <></>}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>

                                    <div className="ps-3 pe-3 mt-4 mb-5">
                                        <WishlistAddForm/>
                                    </div>
                                </>
                            ) : <p className={"ps-4 pe-4 pt-3 pb-3"}>{i18next.t('wishlist.empty').toString()}.</p>}
                        </>
                    )}
                </div>
            </SwipeableDrawer>
        </>
    )
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        // expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
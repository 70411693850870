import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import i18next from "i18next";
import React from "react";
import Image from "next/image";
import Link from "next/link";

export default function FooterAccordionSecureShopping() {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                sx={{background: "#000", pt: "15px", border: "1px solid #fff"}}
                id="panel4a-header">
                <p className="text-md-end">{i18next.t('footer.secureShopping').toString()}</p>
            </AccordionSummary>
            <AccordionDetails sx={{background: "#000", border: "1px solid #fff", borderTop: "none"}}>
                <div className="d-flex flex-row gap-4 mt-3">
                    <Link href={"https://handelsverband.swiss/members/detail/perfecthair-ag/"}
                          target={"_blank"}>
                        <Image src="/images/swiss-online-guarantee.png"
                               height={70}
                               width={70}
                               alt="Swiss Online Garantie"
                               className="d-block mb-2"/>
                    </Link>
                    <Link href={"https://www.trustedshops.ch/bewertung/info_XDE0696BEF034B75F0C5DDB6B84F78687.html"}
                          target={"_blank"}>
                        <Image src="/images/trusted-shop.png"
                               alt="Trusted Shop"
                               className="d-block"
                               height={70}
                               width={70}/>
                    </Link>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}
import i18next from "i18next";
import { NextRouter } from "next/router";
import apiInternal from "../utils/apiInternal";
import routeService from "./route/routeService";

class LanguageService {
    async setLanguage(targetLanguage: string, router: NextRouter) {
        const l = i18next.language;
        await i18next.changeLanguage(targetLanguage);
        const result = await apiInternal().post('/api/v2/language/change', {path: routeService.getAsPath(router.asPath), targetLanguage: targetLanguage, language: l});
        return router.push({pathname: result.data.redirect, query: null}, undefined, {locale: targetLanguage});
    }
}

const languageService = new LanguageService();
export default languageService
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setSelectedQuantity} from "../../stores/product/productSelectorSlice";

export default function DropdownQuantity({quantity, lineItem, height, mode, quantityChange}: any) {
    const [stateQuantity, setQuantity] = useState(quantity);
    const dispatch = useDispatch();
    mode = mode ?? 0;
    const maxPurchase = lineItem.maxPurchase === 0 ? 5 : lineItem.maxPurchase;

    function onQuantityChange(quantity: number) {
        if (!quantityChangeHandler(quantity)) return;
        dispatch(setSelectedQuantity(quantity));

        if (quantityChange) quantityChange(quantity);
    }

    function quantityChangeHandler(quantity: number) {
        if (lineItem.maxPurchase > 0 && quantity > lineItem.maxPurchase) return false;
        setQuantity(quantity);
        return true;
    }

    return (
        <>
            {(mode === 0) ? (
                <>
                    <div className={`position-relative overflow-hidden w-auto`}>
                        <i className={"position-absolute start-0 top-50 h-100 translate-middle-y pt-2 pb-2 ps-2 pe-2 pr-5 fa-solid fa-minus"}
                           onClick={() => onQuantityChange(parseInt(quantity) - 1)}
                           role={"button"}/>
                        <i className={"position-absolute end-0 top-50 h-100 translate-middle-y pt-2 pb-2 pl-5 ps-2 pe-2 fa-solid fa-plus"}
                           onClick={() => onQuantityChange(parseInt(quantity) + 1)}
                           role={"button"}/>
                        <input type={"text"}
                               inputMode={"numeric"}
                               value={quantity}
                               onInput={(e: any) => setQuantity(e.target.value)}
                               onChange={(e: any) => setQuantity(e.target.value)}
                               onBlur={(e: any) => onQuantityChange(e.target.value)}
                               pattern="[0-9]*"
                               style={{height: "30px", width: "30px !important"}}
                               className={"d-block border-light rounded text-center w-100"}/>
                    </div>
                </>
            ) : (
                <div className="w-100 border-light rounded-4 no-hover pt-1 pb-1 ps-2 pe-2 w-100 text-center">
                    {stateQuantity}
                </div>
            )}
        </>
    )
}
import FooterLink from "./Link";
import React from "react";

export default function FooterLinks({links}: any) {
    return (
        <div className="d-flex flex-row flex-wrap justify-content-center">
            {links.map((link: any) => (
                <FooterLink key={link.id} link={link} marginEnd={true}/>
            ))}
        </div>
    )
}